import { useEffect, useState, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useCaptureWorkSheetSanitaryMultiple = () => {
    const { idDetailMicSample } = useParams();
    const history = useHistory();

    const [listCatLotesSanitary, setListCatLotesSanitary] = useState([]);
    const [listMachineSanitary, setListMachineSanitary] = useState([]);
    const [list_determinations, setList_determinations] = useState([]);
    const [fechasample, setFechasample] = useState("");
    const [namesample, setNamesample] = useState("");
    const [nametypesample, setNametypesample] = useState("");
    const [codesample, setCodesample] = useState("");
    const [idwsconfig, setIdwsconfig] = useState(""); //QUITAR
     
    //MachineSanitary
    const [id_machine_sanitarytemp, setId_machine_sanitarytemp] = useState();
    const [id_lote_sanitarytemp, setId_lote_sanitarytemp] = useState();

    const [searchLoteSanitary, setSearchLoteSanitary] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValuesWorkOrder, handlePickerValues] = useForm({
        id_mic_detail_sample: 0,
        name_type_sample: "",
        name_sample: "",
        listProcessConfig_save: [],
    });

    const {
        id_mic_detail_sample,
        name_type_sample,
        name_sample,
        listProcessConfig_save} = formValues;


 
    useEffect(() => {
            getDataCapture();
    }, [history.location]);

    const getDataCapture = async () => {
        let requestOptions = {
            method: 'GET'
        };
    
        try {
            const respuesta = await sendRequest(requestOptions, "WorkSheetSanitary/CaptureSamplesWorkSheet/" + idDetailMicSample);
            
    
            if (respuesta.code === 200) {
                // Llenar listCatLotesSanitary
                const fechaActual = new Date();

                // Convertir la fecha a un formato deseado, como dd/mm/aaaa
                const fechaFormateada = `${fechaActual.getDate()}/${fechaActual.getMonth() + 1}/${fechaActual.getFullYear()}`;

                // Asignar la fecha formateada a setFechasample
                ////console.log(respuesta,"respuesta");
                setFechasample(fechaFormateada);
                setNamesample(respuesta.data[0].name_sample);
                setNametypesample(respuesta.data[0].name_type_sample);
                setCodesample(respuesta.data[0].code);
                
                setListCatLotesSanitary(respuesta.data[0].listCatLotesSanitary);
                // Llenar listMachineSanitary
                setListMachineSanitary(respuesta.data[0].listMachineSanitary);
                setIdwsconfig(respuesta.data[0].idwsconfig);
    
                // Llenar list_determinations
                //setList_determinations(respuesta.data[0].list_determinations);
                // Transformación de listProcessConfig
                if (respuesta.data.length > 0) {
                    const newListProcessConfigSave = respuesta.data.flatMap(item => 
                        item.listProcessConfig?.map(processConfig => {
                            const elementos = processConfig.elementsconfig.map(elemento => ({
                                name: elemento.name,
                                id_type_element: elemento.id_type_element,
                                valor: elemento.id_type_element === 2 ? (elemento.valor === "true" ? true : false) : elemento.valor,
                                comment: elemento.comment,
                                id_elements_config: elemento.id_elements_config,
                                orden: elemento.orden
                            }));
                
                            const dilutions = processConfig.dilutionscoloniesconfig.flatMap(dilution => 
                                processConfig.tubes.map(tube => ({
                                    ...dilution,
                                    id_tubes: dilution.id_tubes !== 0 ? dilution.id_tubes : tube.id_tubes,
                                    nombre_tube: tube.name
                                }))
                            );
                
                            const uniqueCaracteristicas = {};
                            processConfig.dilutionscoloniesconfig.forEach(dilution => {
                                if (!uniqueCaracteristicas[dilution.id_dilutions_colonies]) {
                                    uniqueCaracteristicas[dilution.id_dilutions_colonies] = {
                                        id_dilutions_colonies: dilution.id_dilutions_colonies,
                                        nombre: dilution.name
                                    };
                                }
                            });
                
                            const caracteristicas = Object.values(uniqueCaracteristicas);
                            const tubes = processConfig.tubes.map(tube => ({
                                id_tubes: tube.id_tubes,
                                name: tube.name
                            }));
                
                            const listLotessave = processConfig.listLotesCapture.map(lote => ({
                                id_lote_sanitary: lote.id_lote_sanitary,
                                descripcion: lote.descripcion,
                                n_lote: lote.n_lote,
                                valor: lote.valor,
                            }));
                
                            const listMachinesave = processConfig.listMachineCapture.map(machine => ({
                                id_machine_sanitary: machine.id_machine_sanitary,
                                descripcion: machine.descripcion,
                                clave: machine.clave,
                                valor: machine.valor,
                            }));
                
                            return {
                                id_process_config: processConfig.id_process_config,
                                name: processConfig.name,
                                order_element: processConfig.order_element,
                                id_exam: processConfig.id_exam,
                                name_exam: processConfig.name_exam,
                                elementsconfig: elementos,
                                dilutionscoloniesconfig: dilutions,
                                tubes: tubes,
                                ListMachineCapture: listMachinesave,
                                ListLotesCapture: listLotessave,
                                caracteristicas: caracteristicas,
                                comment_dilutions_colonies: processConfig.comment_dilutions_colonies,
                                worksheet_name: item.worksheet_name, 
                                id_wsconfig: item.idwsconfig,
                                is_lm: processConfig.is_lm, 
                                is_ec: processConfig.is_ec, 
                            };
                        }) || [] // Manejo de caso cuando listProcessConfig sea undefined
                    );
                
                    // Actualizar el estado con la nueva lista determinada
                    handleUpdateValues({
                        ...formValues,
                        listProcessConfig_save: newListProcessConfigSave
                    });
                }
                
                 

                //console.log(formValues, "formValuessadsa");
            } else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code);
            } else if (respuesta.code === 500) {
                createSweet("error", "error", "Áreas", "Ocurrió un problema en el servidor");
            } else {
                createSweet("error", "warning", "Áreas", respuesta.data.msg);
            }
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
    }
    //Select Machine
     //Funcion para elegir determinación
     const handleSelectLote = (e) => {
        let idlotesanitary = e.target.value;
        setId_lote_sanitarytemp(idlotesanitary);
       
    }
    const handleSelectMachineSanitary = (e) => {
        let idmachinesanitary = e.target.value;
        setId_machine_sanitarytemp(idmachinesanitary);
       
    }
    const AddNewLote = (id_exam, id_process_config) => {
        // Obtener el lote seleccionado
        const id_lote_sanitary = id_lote_sanitarytemp;
        const selectedLote = listCatLotesSanitary.find(lote => lote.id_lote_sanitary === id_lote_sanitary);
        
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = [...formValues.listProcessConfig_save];
        
        // Buscar el proceso por id_exam
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
        
        if (existingProcess) {
                // Verificar si el lote ya existe en la lista de lotes del paso
                const loteExists = existingProcess.ListLotesCapture.some(lote => lote.id_lote_sanitary === selectedLote.id_lote_sanitary);
                
                if (!loteExists) {
                    // Si el lote no existe, agréguelo a la lista de lotes del paso
                    existingProcess.ListLotesCapture = [...(existingProcess.ListLotesCapture || []), selectedLote];
                } else {
                    // Si el lote ya existe, muestra un mensaje de error
                    createSweet("error", "warning", "Lote Existente", "El lote seleccionado ya existe en la lista.");
                    return; // Salir de la función para evitar la actualización del estado
                }
           
        } else {
           
            newListProcessSave.push({
                id_process_config: id_process_config,
                ListLotesCapture: [selectedLote]
            });
        }
        
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    
    const AddNewMachine = (id_exam, id_process_config) => {
        // Obtener el lote seleccionado
        const id_machine_sanitary_t = id_machine_sanitarytemp;
        const selectMachine = listMachineSanitary.find(machine => machine.id_machine_sanitary === id_machine_sanitary_t);
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Buscar el proceso por id_exam
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            // Verificar si la máquina ya existe en la lista
            const machineExists = existingProcess.ListMachineCapture.some(machine => machine.id_machine_sanitary === selectMachine.id_machine_sanitary);
            
            if (!machineExists) {
                // Si la máquina no existe, agréguela a la lista de máquinas sanitarias guardadas
                existingProcess.ListMachineCapture = [...(existingProcess.ListMachineCapture || []), selectMachine];
            } else {
                // Si la máquina ya existe, muestra un mensaje de error
                createSweet("error", "warning", "Máquina Existente", "La máquina seleccionada ya existe en la lista.");
                return; // Salir de la función para evitar la actualización del estado
            }
        } else {
            // Si el proceso no existe, cree uno nuevo con la nueva máquina sanitaria
            newListProcessSave.push({
                id_process_config: id_process_config,
                ListMachineCapture: [selectMachine]
            });
        }
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    const handleDeleteMachine = (machineIndex, id_process_config) => {
        // Copia la lista de máquinas sanitarias guardadas
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Busca el proceso por id_exam
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            // Verifica si existe la propiedad 'ListMachineCapture' en existingProcess
            if (existingProcess.ListMachineCapture) {
                // Elimina la máquina en el índice dado
                existingProcess.ListMachineCapture.splice(machineIndex, 1);
    
                // Actualiza el estado con la nueva lista de procesos guardados
                handleUpdateValues({
                    ...formValues,
                    listProcessConfig_save: newListProcessSave
                });
            }
        }
    };
    const handleDeleteLote = (loteIndex, id_process_config) => {
        // Copia la lista de máquinas sanitarias guardadas
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Busca el proceso por id_exam
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            // Verifica si existe la propiedad 'list_machine_sanitary_save' en existingProcess
            if (existingProcess.ListLotesCapture) {
                // Elimina la máquina en el índice dado
                existingProcess.ListLotesCapture.splice(loteIndex, 1);
    
                // Actualiza el estado con la nueva lista de procesos guardados
                handleUpdateValues({
                    ...formValues,
                    listProcessConfig_save: newListProcessSave
                });
            }
        }
    };
    const handleMachineValueChange = (e, machineIndex, id_process_config) => {
        const newValue = e.target.value;
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = formValues.listProcessConfig_save.map(process => {
            if (process.id_process_config === id_process_config) {
                // Asegurarse de que ListMachineCapture exista
                const ListMachineCapture = process.ListMachineCapture ? [...process.ListMachineCapture] : [];
    
                // Actualizar el valor específico en ListMachineCapture
                ListMachineCapture[machineIndex] = { ...ListMachineCapture[machineIndex], valor: newValue };
    
                return {
                    ...process,
                    ListMachineCapture
                };
            }
            return process;
        });
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    
    
    
    const handleLoteValueChange = (e, loteIndex, id_process_config) => {
        const newValue = e.target.value;
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = formValues.listProcessConfig_save.map(process => {
            if (process.id_process_config === id_process_config) {
                // Asegurarse de que ListLotesCapture exista
                const ListLotesCapture = process.ListLotesCapture ? [...process.ListLotesCapture] : [];
                
                // Actualizar el valor específico en ListLotesCapture
                ListLotesCapture[loteIndex] = { ...ListLotesCapture[loteIndex], valor: newValue };
    
                return {
                    ...process,
                    ListLotesCapture
                };
            }
            return process;
        });
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    
    
    
    
    
    const toggle = () => {
        setModal(!modal);
    }
    const changeInputCarac = (e, name, id_process_config, id_dilutions_colonies, id_tubes) => {
        let valor = e.target.value;
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Buscar el proceso por id_process_config
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            if (!existingProcess.dilutionscoloniesconfig) {
                existingProcess.dilutionscoloniesconfig = [];
            }
    
            // Buscar si la característica ya existe
            const existingCaractIndex = existingProcess.dilutionscoloniesconfig.findIndex(caract => 
                caract.id_dilutions_colonies === id_dilutions_colonies && caract.id_tubes === id_tubes
            );
    
            if (existingCaractIndex !== -1) {
                // Si la característica ya existe, modificar su valor
                existingProcess.dilutionscoloniesconfig[existingCaractIndex].valor = valor;
            } else {
                // Si la característica no existe, añadirla a la lista de características del proceso
                existingProcess.dilutionscoloniesconfig.push({
                    id_dilutions_colonies: id_dilutions_colonies,
                    id_tubes: id_tubes,
                    name: name,
                    valor: valor
                });
            }
        } else {
            // Si el proceso no existe, crear uno nuevo con la nueva característica
            newListProcessSave.push({
                id_process_config: id_process_config,
                dilutionscoloniesconfig: [{
                    id_dilutions_colonies: id_dilutions_colonies,
                    id_tubes: id_tubes,
                    name: name,
                    valor: valor
                }]
            });
        }
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    
    const changeInputElement = (e, name,comment,orden,id_process_config, id_elements_config, id_type_element) => {
        const valor = e.target.value;
        const elementconfig = {
            id_elements_config: id_elements_config,
            name: name,
            comment: comment,
            orden: orden,
            valor: valor,
            id_type_element: id_type_element
        };
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Buscar el proceso por id_process_config
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            if (!existingProcess.elementsconfig) {
                existingProcess.elementsconfig = [];
            }
            // Buscar si el elemento ya existe
            const existingElementIndex = existingProcess.elementsconfig.findIndex(elemento => elemento.id_elements_config === id_elements_config && elemento.id_type_element === id_type_element);
    
            if (existingElementIndex !== -1) {
                // Si el elemento ya existe, modificar su valor
                existingProcess.elementsconfig[existingElementIndex].valor = valor;
            } else {
                // Si el elemento no existe, añadirlo a la lista de elementos del proceso
                existingProcess.elementsconfig.push(elementconfig);
            }
        } else {
            // Si el proceso no existe, crear uno nuevo con la nueva característica
            newListProcessSave.push({
                id_process_config: id_process_config,
                elementsconfig: [elementconfig]
            });
        }
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
   
    
    const changeInputElementCheck = (e, name, comment, orden, id_process_config, id_elements_config, id_type_element) => {
        // Obtener el valor correcto basado en el tipo de input
        const valor = id_type_element === 2 ? e.target.checked : e.target.value;
    
        const elementconfig = {
            id_elements_config: id_elements_config,
            name: name,
            comment: comment,
            orden: orden,
            valor: valor,
            id_type_element: id_type_element
        };
    
        // Crear una copia de la lista de procesos guardados
        const newListProcessSave = [...formValues.listProcessConfig_save];
    
        // Buscar el proceso por id_process_config
        const existingProcess = newListProcessSave.find(process => process.id_process_config === id_process_config);
    
        if (existingProcess) {
            if (!existingProcess.elementsconfig) {
                existingProcess.elementsconfig = [];
            }
            // Buscar si el elemento ya existe
            const existingElementIndex = existingProcess.elementsconfig.findIndex(elemento => elemento.id_elements_config === id_elements_config && elemento.id_type_element === id_type_element);
    
            if (existingElementIndex !== -1) {
                // Si el elemento ya existe, modificar su valor
                existingProcess.elementsconfig[existingElementIndex].valor = valor;
            } else {
                // Si el elemento no existe, añadirlo a la lista de elementos del proceso
                existingProcess.elementsconfig.push(elementconfig);
            }
        } else {
            // Si el proceso no existe, crear uno nuevo con la nueva característica
            newListProcessSave.push({
                id_process_config: id_process_config,
                elementsconfig: [elementconfig]
            });
        }
    
        // Actualizar el estado con la nueva lista de procesos guardados
        handleUpdateValues({
            ...formValues,
            listProcessConfig_save: newListProcessSave
        });
    };
    
    
    
    
    
    
    
    const saveWSS = async () => {
        //console.log("formvalues-listProcessConfig_save",formValues.listProcessConfig_save);
        const listProcessConfig_save_filtered = formValues.listProcessConfig_save.map(listProcessConf => ({
            id_process_config: listProcessConf.id_process_config,
            name: listProcessConf.name,
            order_element: listProcessConf.order_element,
            id_exam: listProcessConf.id_exam,
            name_exam: listProcessConf.name_exam,
            elementsconfig: listProcessConf.elementsconfig,
            dilutionscoloniesconfig: listProcessConf.dilutionscoloniesconfig,
            ListMachineCapture: listProcessConf.ListMachineCapture,
            ListLotesCapture: listProcessConf.ListLotesCapture,
            tubes: listProcessConf.tubes,
            id_wsconfig:listProcessConf.id_wsconfig,
            is_lm: listProcessConf.is_lm, 
            is_ec: listProcessConf.is_ec, 
        }));
        console.log(listProcessConfig_save_filtered,"listProcessConfig_save_filtered");
        
        let body = {
            id_mic_detail_sample: idDetailMicSample,
            name_type_sample: namesample,
            name_sample: nametypesample,
            idwsconfig: idwsconfig,
            listProcessConfig:listProcessConfig_save_filtered
        };
        //console.log(body,"body");

        let listTemp = await handleRequest('POST', "WorkSheetSanitary/SaveCaptureSamplesWorkSheet", "wOR", body);

        if (listTemp !== null) {
           
                createSweet("success", "success", "Se creo correctamente la Hoja de Trabajo");
            

        }
    }
   
   
    
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    const ImprimirPdf = async () => {
        let id_branch = localStorage.getItem("branchId");
        let data = {
            "id_mic_detail_sample": idDetailMicSample,
            "id_branch": id_branch,
        }
    
        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");
    
        try {
            const response = await fetch("/api/WorkSheetSanitary/CreatePdf", {
                method: 'POST',
                body: raw,
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            });
                //console.log(response.status);
            if (response.status !== 200) {
                createSweet("warning", "warning", "No se descargó el PDF");
                return;
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "HOJA-DE-TRABAJO-" + codesample + ".pdf");
            createSweet("success", "Descargando", "Se descargó el PDF");
            document.body.appendChild(link);
            link.style.display = 'none';
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error al generar el PDF:", error);
            createSweet("error", "Error", "Ocurrió un error al intentar descargar el PDF");
        }
    }
    
    


    return {
       
        toggle,
        modal,
        modalTitle,
        listCatLotesSanitary,
        listMachineSanitary,
        list_determinations,
        id_machine_sanitarytemp,
        fechasample, 
        namesample,
        codesample, 
        nametypesample,
        id_lote_sanitarytemp,
        handleSelectLote,
        listProcessConfig_save,
        searchLoteSanitary,
        AddNewLote,
        AddNewMachine,
        handleSelectMachineSanitary,
        formValues,
        changeInputCarac,
        changeInputElement,
        handleMachineValueChange,
        handleLoteValueChange,
        saveWSS,
        ImprimirPdf,
        handleDeleteMachine,
        handleDeleteLote,
        idwsconfig,
        changeInputElementCheck
    }
}
